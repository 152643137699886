import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import FunDayOct2023 from "./FunDayOct2023/FunDayOct2023";
import Overlay from "./Overlay/Overlay";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/fun-day-oct-2023" Component={FunDayOct2023} />
        <Route path="/overlay" Component={Overlay} />
      </Routes>
    </div>
  );
}

export default App;
