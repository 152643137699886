import { useEffect, useState } from "react";
import "./Overlay.css";

import { useSearchParams } from "react-router-dom";

function Overlay() {
  let [searchParams] = useSearchParams();
  const [showInstructions, setShowInstructions] = useState(false);
  useEffect(() => {
    var text = searchParams.get("text");
    var period = searchParams.get("period");
    if (text) {
      setShowInstructions(false);
      animation(text, period);
    } else {
      setShowInstructions(true);
    }
  }, [searchParams]);

  return (
    <div className="overlay">
      <div className="typewrite">
        <span className="wrap"></span>
      </div>
      {showInstructions ? (
        <div className="instructions">
          <h2>Text Overlay for OBS</h2>
          <p>
            This page can be used as text overlays in OBS. Please not that since
            this is experimental at the moment, the url might change without
            notice.
          </p>
          <h3>Config</h3>
          <p>
            Use these url parameters to config this page
            <ul>
              <li>
                period=2000 - the delay before it starts to "press" backspace to
                remove text
              </li>
              <li>
                text=["Hello.", "World."] - an array of text lines that you want
                to display
              </li>
            </ul>
            Try it out:&nbsp;
            <a href='https://nystrom.info/overlay?period=2000&text=["Hello.", "World."]'>
              https://nystrom.info/overlay?period=2000&text=&#91;&quot;Hello.&quot;,&nbsp;&quot;World.&quot;&#93;
            </a>
          </p>
          <h3>Example config in OBS</h3>
          <p>
            This is an example of a config for a Browser source that you can use
            in OBS. Set the width so that the text doesn't wrap. Use Custom CSS
            for the class <code>.typewrite</code> to override the style for the
            text. Any valid CSS should work. The <code>background-color</code>{" "}
            is default for how OBS makes the background transparent.
            <br />
            I've added the{" "}
            <code>
              body::-webkit-scrollbar{"{"} display: none; {"}"}
            </code>{" "}
            to hide the scrollbar in the Browser source.
          </p>
          <picture>
            <img
              src={require("../assets/obs-stream-text-overlay.png")}
              width={600}
              alt="OBS Stream text overlay"
            />
          </picture>
        </div>
      ) : null}
    </div>
  );
}

class TxtType {
  constructor(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  }
  tick() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  }
}

function animation(text, period) {
  var elements = document.getElementsByClassName("typewrite");
  for (var i = 0; i < elements.length; i++) {
    if (text) {
      new TxtType(elements[i], JSON.parse(text), period);
    }
  }
  // INJECT CSS
  var css = document.createElement("style");
  css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
  document.body.appendChild(css);
}

export default Overlay;
