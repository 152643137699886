import "./Home.css";

function Home() {
  return (
    <div className="header header-home">
      <div className="banner-text text-center">
        <h1>I'm Lars Nyström</h1>
        <p>
          Systems and their data, keeping 'em all together.
          <br /> Love to travel to places with lots of sun or lots of snow.
          <br />
          <b>Skinnskatteberg -&gt; Gothenburg -&gt; Skövde</b>
        </p>
        <div className="socialicons">
          <ul>
            <li>
              <a
                href="https://www.twitch.tv/littleandi77"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet={require("../assets/Twitch.webp")}
                  />
                  <img src={require("../assets/Twitch.png")} alt="Twitch" />
                </picture>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/LittleAndi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet={require("../assets/Github.webp")}
                  />
                  <img src={require("../assets/Github.png")} alt="Github" />
                </picture>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/littleandi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet={require("../assets/Instagram.webp")}
                  />
                  <img
                    src={require("../assets/Instagram.png")}
                    alt="Instagram"
                  />
                </picture>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/larsnystrom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet={require("../assets/Linkedin.webp")}
                  />
                  <img src={require("../assets/Linkedin.png")} alt="LinkedIn" />
                </picture>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/lasse77"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet={require("../assets/Facebook.webp")}
                  />
                  <img src={require("../assets/Facebook.png")} alt="Facebook" />
                </picture>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/433eros"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet={require("../assets/Twitter.webp")}
                  />
                  <img src={require("../assets/Twitter.png")} alt="Twitter" />
                </picture>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;
